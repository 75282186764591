import { chartTooltip } from '@/utils'
import { formatPayment } from '@/constants/config'

export const profitabilityChartOptions = {
  legend: {
    position: 'bottom',
    labels: {
      padding: 20,
      usePointStyle: false,
      fontSize: 12
    },
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
          padding: 20,
          callback: function (value, index, ticks) {
            return Math.abs(value) < 1000 ? value : `${value / 1000}k`
          }
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        },
        barPercentage: 0.6,
        categoryPercentage: 1
      }
    ]
  },
  tooltips: {
    backgroundColor: chartTooltip.backgroundColor,
    titleFontColor: chartTooltip.titleFontColor,
    borderColor: chartTooltip.borderColor,
    borderWidth: chartTooltip.borderWidth,
    bodyFontColor: chartTooltip.bodyFontColor,
    bodySpacing: chartTooltip.bodySpacing,
    xPadding: chartTooltip.xPadding,
    yPadding: chartTooltip.yPadding,
    cornerRadius: chartTooltip.cornerRadius,
    callbacks: {
      label: function (tooltipItem, data) {
        return formatPayment(Number(tooltipItem.yLabel))
      }
    }
  }
}

export const volumeChartOptions = {
  legend: {
    position: 'bottom',
    labels: {
      padding: 20,
      usePointStyle: false,
      fontSize: 12
    },
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
          padding: 20
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        },
        barPercentage: 0.6,
        categoryPercentage: 1
      }
    ]
  },
  tooltips: chartTooltip
}

export const accuracyChartOptions = {
  legend: {
    position: 'bottom',
    labels: {
      padding: 20,
      usePointStyle: false,
      fontSize: 12
    },
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.1)',
          drawBorder: false
        },
        ticks: {
          beginAtZero: true,
          suggestedMin: 0,
          suggestedMax: 100,
          padding: 20
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        },
        barPercentage: 0.6,
        categoryPercentage: 1
      }
    ]
  },
  tooltips: {
    backgroundColor: chartTooltip.backgroundColor,
    titleFontColor: chartTooltip.titleFontColor,
    borderColor: chartTooltip.borderColor,
    borderWidth: chartTooltip.borderWidth,
    bodyFontColor: chartTooltip.bodyFontColor,
    bodySpacing: chartTooltip.bodySpacing,
    xPadding: chartTooltip.xPadding,
    yPadding: chartTooltip.yPadding,
    cornerRadius: chartTooltip.cornerRadius,
    callbacks: {
      label: function (tooltipItem, data) {
        return Number(tooltipItem.yLabel).toFixed(2)
      }
    }
  }
}
