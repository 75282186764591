<template>
  <div class="rollback-card">
    <b-card no-body>
      <b-row class="text-center mx-auto w-100 my-3">
        <b-col cols="2" class="p-0 text-center">
          <b-avatar
            size="3.5em"
            :text="getNameInitials(teamWeek.week_name)"
            :variant="setWeekVariant(teamWeek.status_id)"
            :disabled="disableWeek(teamWeek) || disabled"
            class="btn-shadow"
            v-tooltip="{
              content: teamWeek.week_name,
              placement: 'bottom',
              classes: ['light'],
              offset: 10
            }"
          ></b-avatar>
        </b-col>
        <b-col
          cols="2"
          v-for="(day, index) in teamWeek.team_week_days"
          :key="`day-${index}`"
          class="p-0"
        >
          <div class="rollback-wrapper">
            <b-avatar
              size="3.5em"
              :text="getNameInitials(day.day_name)"
              :variant="setWeekDayVariant(day)"
              :disabled="disableDay(day) || disabled"
              button
              @click="onRollbackDayClick(day)"
              class="btn-shadow"
              v-tooltip="{
                content: day.day_name,
                placement: 'bottom',
                classes: ['light'],
                offset: 10
              }"
            ></b-avatar>
            <span
              v-if="day.id === value"
              :key="`check-${value}`"
              class="numberCircle animate__animated animate__bounceIn"
            >
              <i class="fa fa-history"></i>
            </span>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { helperMixin } from '@/mixins/helperMixin'
export default {
  props: {
    teamWeek: {
      type: Object,
      default: () => {},
      required: true
    },
    value: {
      type: [Number, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mixins: [helperMixin],
  methods: {
    setWeekVariant(statusId) {
      let variant = 'info'
      if (statusId === 0) {
        variant = 'light'
      } else if (statusId === 3) {
        variant = 'outline-info'
      } else if (statusId === 1) {
        variant = 'light'
      } else {
        variant = 'info'
      }

      return variant
    },
    disableWeek(teamWeek) {
      let disabled = false
      if (teamWeek.status_id === 0 || teamWeek.status_id === 1) {
        disabled = true
      }
      return disabled
    },
    setWeekDayVariant(day) {
      let variant = 'light'
      if (day.rollback) {
        if (day.id === this.value) {
          variant = 'danger'
        } else {
          variant = 'outline-danger'
        }
      } else {
        if (day.current_day) {
          variant = 'new'
        }
      }

      return variant
    },
    disableDay(teamWeekDay) {
      let disabled = false
      if (teamWeekDay.rollback) {
        disabled = false
      } else {
        disabled = true
      }
      return disabled
    },
    onRollbackDayClick(day) {
      let self = this
      if (self.value === day.id) {
        self.selectedWeekDayId = null
        return
      }

      self.selectedWeekDayId = day.id
    }
  },
  computed: {
    selectedWeekDayId: {
      get() {
        this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.b-avatar.disabled {
  opacity: 0.2 !important;
}

.rollback-wrapper {
  width: 3.5em;
  height: 3.5em;
  margin: 0 auto;
  position: relative;

  .numberCircle {
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;

    background: #28a745;
    border: 1px solid #28a745;
    color: #fff;
    text-align: center;

    i {
      font-size: 12px !important;
    }
  }
}
</style>
