<template>
  <b-dropdown variant="empty" toggle-class="p-0" menu-class="a-shadow-sm" no-caret>
    <template slot="button-content">
      <div class="d-flex">
        <p v-if="badge" class="p-0 m-0 self-align-center">
          <b-badge :variant="badgeVariant">
            <span>{{ selected[itemValue] }}</span>
            <i class="fa fa-angle-down self-align-center ml-1"></i>
          </b-badge>
        </p>
        <span v-else class="self-align-center">{{ selected[itemValue] }}</span>
      </div>
    </template>
    <b-dropdown-item
      v-for="(item, index) in options"
      :key="`item-${index}`"
      :active="setItemActive(index)"
      @click="onItemClick(item)"
    >
      <span>{{ item[itemValue] }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
export default {
  components: {
    PulseLoader
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: 'Your input'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    value: {
      type: Object,
      default: null
    },
    badge: {
      type: Boolean,
      default: false
    },
    badgeVariant: {
      type: String,
      default: 'outline-primary'
    },
    defaultItemIndex: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      fill: variables.primaryColor
      // selectedItem: null
    }
  },
  methods: {
    onItemClick(item) {
      let self = this
      // self.selectedItem = item
      this.$emit('input', item)
    },
    setItemActive(index) {
      let self = this
      return self.selected ? index === self.options.indexOf(self.selected) : false
    }
  },
  computed: {
    selected: {
      get() {
        let self = this

        let item = {
          [self.itemValue]: self.placeholder
        }

        if (self.defaultItemIndex >= 0 && self.defaultItemIndex <= self.options.length) {
          item = self.options[self.defaultItemIndex]
        }

        if (self.value) {
          item = self.value
        }

        return item
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.invalid-tooltip {
  display: block;
}
</style>
