<template>
  <div class="rollback-control">
    <b-overlay :show="loading" rounded="sm" bg-color="white">
      <div v-if="!loading">
        <b-form @submit.prevent="onRollbackSave" class="text-center">
          <div v-for="(weekDay, index) in teamWeeks" :key="`weekDay-${index}`" class="mb-3">
            <team-week-day-rollback-card
              :teamWeek="weekDay"
              v-model="rollbackDay"
              :disabled="disabled"
            ></team-week-day-rollback-card>
          </div>
          <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <div v-if="rollbackDay">
              <b-alert variant="danger" show>
                <p class="m-0">
                  Please type
                  <b
                    :key="`rollback-prompt-${rollbackDay}`"
                    class="animate__animated animate__fadeIn"
                    >"{{ rollbackWeekName }} {{ rollbackDayName }}"</b
                  >
                  to authenticate the rollback to
                  <span :key="`rollback-${rollbackDay}`" class="animate__animated animate__fadeIn">
                    <b>{{ rollbackWeekName }}, {{ rollbackDayName }}</b>
                  </span>
                </p>
              </b-alert>
              <div class="d-flex">
                <b-form-input
                  v-model="confirmedAction"
                  :disabled="disabled"
                  class="align-self-center"
                  v-tooltip="{
                    content: `Please type ${rollbackWeekName} ${rollbackDayName}`,
                    show: $v.confirmedAction.$error,
                    trigger: 'manual',
                    placement: 'bottom',
                    classes: ['light'],
                    offset: 5
                  }"
                >
                </b-form-input>
                <processing-button
                  variant="primary"
                  size="sm"
                  label="Save"
                  class="align-self-center btn-shadow ml-2"
                  :processing="processing"
                  :disabled="!hasInputChanged || disabled"
                ></processing-button>
              </div>
            </div>
          </transition>
        </b-form>
      </div>
      <div v-else class="control-loader"></div>
      <template #overlay>
        <running-loader loading></running-loader>
      </template>
    </b-overlay>

    <b-modal
      id="rollback-confirm-modal"
      ref="rollback-confirm-modal"
      v-if="allTeamWeekDays && rollbackWeek && rollbackDay"
      v-model="showRollbackModal"
      hide-header
      hide-footer
      @hide="onRollbackModalTrigger(false)"
      body-class="rollback-modal-body text-center"
    >
      <b-form @submit.prevent="onRollbackConfirmClick" class="text-center">
        <img
          class="modal-img my-4"
          src="/assets/img/svg/controls/running_outline_rollback.svg"
          alt="Rollback"
        />
        <h3>Are you sure you want to rollback?</h3>
        <p class="">
          <b-badge variant="info">{{ currentWeekName }} {{ currentDayName }}</b-badge>
          <i
            class="fa fa-arrow-right animate__animated animate__fadeIn animate__infinite animate__slow mx-2 text-danger"
          ></i>
          <b-badge variant="info">{{ rollbackWeekName }} {{ rollbackDayName }}</b-badge>
        </p>
        <div>
          <processing-button
            variant="warning"
            size="lg"
            label="Confirm"
            class="align-self-center btn-shadow my-2"
            :processing="processing"
          ></processing-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TeamWeekDayRollbackCard from './TeamWeekDayRollbackCard.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TeamWeekDayRollbackCard,
    ProcessingButton
  },
  data() {
    return {
      teamWeeks: [],
      rollbackDay: null,
      rollbackWeek: null,
      processing: false,
      confirmedAction: null,
      showRollbackModal: false
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      confirmedAction: {
        required,
        confirmed: (value) => {
          return (
            value.toLowerCase() ===
            `${this.rollbackWeekName.toLowerCase()} ${this.rollbackDayName.toLowerCase()}`
          )
        }
      }
    }
  },
  methods: {
    ...mapActions(['rollbackTeamWeekDay']),
    async onRollbackSave() {
      let self = this
      self.$v.$touch()

      // return if form error is captured
      if (self.$v.confirmedAction.$pending || self.$v.confirmedAction.$error) return

      self.onRollbackModalTrigger(true)
    },
    async resetData() {
      let self = this
      self.rollbackWeek = null
      self.rollbackDay = null
      self.confirmedAction = null
      await self.$v.$reset()
    },
    async onRollbackModalTrigger(value) {
      this.showRollbackModal = value
    },
    async onRollbackConfirmClick() {
      let self = this

      let payload = {
        teamId: self.allTeamWeekDays.team_id,
        teamWeekId: self.rollbackWeek.id,
        fromWeekDayId: self.rollbackDay,
        toWeekDayId: self.activeTeamWeekDay.id
      }

      self.processing = true
      await self.rollbackTeamWeekDay(payload)
      self.processing = false
      await self.onRollbackModalTrigger(false)
      await self.resetData()
      await self.$emit('rollbackSaved')
    }
  },
  computed: {
    ...mapGetters(['allTeamWeekDays']),
    rollbackWeekName() {
      let self = this
      if (self.rollbackWeek) {
        return self.rollbackWeek.week_name
      }
    },
    rollbackDayName() {
      let self = this
      if (self.rollbackWeek) {
        return self.rollbackWeek.team_week_days.find((twd) => twd.id === self.rollbackDay).day_name
      }
    },
    currentWeekName() {
      let self = this
      return self.activeTeamWeek.week_name
    },
    currentDayName() {
      let self = this
      return self.activeTeamWeekDay.day_name
    },
    hasInputChanged() {
      return this.confirmedAction ? true : false
    },
    activeTeamWeeks() {
      let self = this
      return self.allTeamWeekDays.team_weeks
    },
    activeTeamWeek() {
      let self = this
      return self.activeTeamWeeks.find((tw) => tw.status_id === 2)
    },
    activeTeamWeekDay() {
      let self = this
      return self.activeTeamWeek.team_week_days.find((wd) => wd.current_day)
    }
  },
  watch: {
    allTeamWeekDays: {
      handler(value) {
        if (value) {
          let self = this
          self.teamWeeks = value.team_weeks
          self.teamWeeks.forEach((tw) => {
            tw.team_week_days.map((d) => {
              if (d.rollback) {
                d.selected = false
              }
            })
          })
          self.resetData()
        }
      },
      deep: true
    },
    rollbackDay(value) {
      let self = this
      if (value) {
        self.rollbackWeek = self.allTeamWeekDays.team_weeks.find((tw) =>
          tw.team_week_days.some((d) => d.id === value)
        )
      }
    },
    confirmedAction(value) {
      if (value === '') {
        this.$v.$reset()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-img {
  height: 8.5rem;
}

.control-loader {
  height: 480px;
}
</style>
