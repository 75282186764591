<template>
  <b-row class="form-body">
    <b-col cols="12 my-2">
      <label class="settings-label pl-0 mb-2 col-12">Require approval code?</label>
      <div class="d-flex">
        <b-form-radio
          v-model="settings.approvalCode"
          name="ac-radios"
          :value="1"
          class="align-self-center d-inline-block"
        >
          <span>Yes</span>
        </b-form-radio>
        <b-form-radio
          v-model="settings.approvalCode"
          name="ac-radios"
          :value="0"
          class="align-self-center d-inline-block mx-4"
        >
          <span>No</span>
        </b-form-radio>
      </div>
    </b-col>
    <b-col cols="12 my-2">
      <label class="settings-label pl-0 mb-2 col-12">Work Complexity</label>
      <div class="d-flex">
        <b-form-radio
          v-model="settings.complexity"
          name="wc-radios"
          :value="2"
          class="align-self-center d-inline-block"
        >
          <span>Letter</span>
        </b-form-radio>
        <b-form-radio
          v-model="settings.complexity"
          name="wc-radios"
          :value="1"
          class="align-self-center d-inline-block mx-4"
        >
          <span>Table</span>
        </b-form-radio>
      </div>
    </b-col>

    <b-col cols="12 my-2">
      <label class="settings-label pl-0 mb-2 col-12">Currency</label>
      <b-dropdown variant="empty" toggle-class="p-0" menu-class="mt-2 a-shadow-sm" no-caret>
        <template slot="button-content">
          <b-badge
            v-if="selectedCurrency"
            :key="`currency-${selectedCurrency.id}`"
            pill
            variant="warning"
            class="btn-shadow animate__animated animate__bounceIn"
          >
            {{ selectedCurrency.name }}
          </b-badge>
        </template>
        <b-dropdown-item
          v-for="(item, index) in localeOptions"
          :key="`item-${index}`"
          @click="onItemClick(item)"
        >
          <span>{{ item.name }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import BaseInput from '@/components/Common/BaseInput.vue'
import BaseSelection from '@/components/Common/BaseSelection.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import { locales } from '@/constants/config'

export default {
  props: {
    weekId: {
      type: Number,
      default: 0
    },
    approvalCode: {
      type: Number,
      default: 0
    },
    complexity: {
      type: Number,
      default: 0
    },
    appLocale: {
      type: String,
      default: 'en-AU'
    }
  },
  components: {
    BaseInput,
    BaseSelection,
    ProcessingButton
  },
  data() {
    return {
      processing: false,
      settings: this.createDefaultSettings(),
      appLocaleOptions: [],
      selectedLocale: this.appLocale
      // selectedCurrency: null
    }
  },
  methods: {
    createDefaultSettings() {
      let self = this
      return {
        approvalCode: self.approvalCode,
        complexity: self.complexity,
        appLocale: self.appLocale
      }
    },
    onItemClick(item) {
      let self = this
      self.selectedLocale = item.id
      self.settings.appLocale = item.id
    }
  },
  computed: {
    localeOptions() {
      return locales.map((item) => {
        return {
          id: item.locale,
          name: `${item.currency} (${item.symbol})`,
          symbol: item.symbol
        }
      })
    },
    selectedCurrency() {
      let self = this
      return self.localeOptions.find((opt) => opt.id === self.appLocale)
    }
  },
  watch: {
    settings: {
      handler(value) {
        this.$emit('settingsUpdated', { slideOne: value })
      },
      deep: true
    }
  }
}
</script>
