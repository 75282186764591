<template>
  <div class="chart-legend overall-result w-100">
    <b-row class="mx-2 legend-item mb-2">
      <b-col
        v-for="(data, index) in chartDataSet"
        :key="`chart-data-${index}`"
        class="text-center mb-2 p-0"
        :class="{ 'option-hidden': data.hidden }"
      >
        <p
          class="align-self-center m-0 font-semi-bold team-name mx-auto"
          v-tooltip="{
            content: data.label,
            placement: 'top',
            classes: ['light']
          }"
        >
          {{ data.label }}
        </p>
        <p v-if="formatType === 2" class="m-0 text-center font-light font-size-90">
          ({{ data.data[0] | formatPayment }})
        </p>
        <p v-else-if="formatType === 3" class="m-0 text-center font-light font-size-90">
          ({{ data.data[0] | formatRatePercentage }})
        </p>
        <p v-else class="m-0 text-center font-light font-size-90">({{ data.data[0] }})</p>
        <div
          class="px-3 py-1 mb-1 mx-auto align-self-center chart-legend-line"
          :style="`border: 1px solid ${data.borderColor}; backgroundColor: ${data.backgroundColor}`"
        ></div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { formatPayment, formatRatePercentage } from '@/constants/config'
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    formatType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      chartDataSet: []
    }
  },
  methods: {
    initialiseOptions() {
      let self = this
      self.chartDataSet = _.cloneDeep(self.options)
    },
    onDataClick(index) {
      let self = this
      let clickedData = self.chartDataSet[index]
      clickedData.hidden = !clickedData.hidden
      self.$set(self.chartDataSet, index, clickedData)
      self.$emit('optionClicked', index)
    }
  },
  filters: {
    formatPayment: function (value) {
      return formatPayment(value)
    },
    formatRatePercentage: function (value) {
      return formatRatePercentage(value, 2)
    }
  },
  mounted() {
    this.initialiseOptions()
  }
}
</script>
