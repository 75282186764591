export const workAllocationsTable = {
  fields: [
    {
      key: 'user_name',
      label: 'Name',
      thClass: 'align-middle text-left w-30',
      tdClass: 'align-middle text-left w-30'
    },
    {
      key: 'allocated',
      label: 'Total',
      thClass: 'align-middle text-center w-10',
      tdClass: 'align-middle text-center w-10'
    },
    {
      key: 'lodged',
      label: 'Lodged',
      thClass: 'align-middle text-center w-10',
      tdClass: 'align-middle text-center w-10'
    },
    {
      key: 'remaining',
      label: 'Remaining',
      thClass: 'align-middle text-center w-10',
      tdClass: 'align-middle text-center w-10'
    },
    {
      key: 'add',
      label: 'Allocate',
      thClass: 'align-middle text-center w-20',
      tdClass: 'align-middle text-center w-20'
    },
    {
      key: 'remove',
      label: 'Remove',
      thClass: 'align-middle text-center w-20',
      tdClass: 'align-middle text-center w-20'
    }
  ],
  data: [
    {
      avatar: '',
      user_name: '--',
      user_role: '--',
      allocated: 0,
      lodged: 0,
      remaining: 0,
      policy_preference: 'random'
    }
  ]
}

export const workAllocationsTableFooter = {
  fields: [
    {
      key: 'user_name',
      label: 'Name',
      thClass: 'd-none',
      tdClass: 'align-middle text-left w-30 font-semi-bold'
    },
    {
      key: 'allocated',
      label: 'Total Allocated',
      thClass: 'd-none',
      tdClass: 'align-middle text-center w-10'
    },
    {
      key: 'lodged',
      label: 'Lodged',
      thClass: 'd-none',
      tdClass: 'align-middle text-center w-10'
    },
    {
      key: 'remaining',
      label: 'Remaining',
      thClass: 'd-none',
      tdClass: 'align-middle text-center w-10'
    },
    {
      key: 'add',
      label: 'Allocate',
      thClass: 'd-none',
      tdClass: 'align-middle text-center w-20'
    },
    {
      key: 'remove',
      label: 'Remove',
      thClass: 'd-none',
      tdClass: 'align-middle text-center w-20'
    }
  ],
  data: [
    {
      avatar: '',
      user_name: 'Total',
      user_role: '',
      allocated: 0,
      lodged: 0,
      remaining: 0,
      policy_preference: 'random'
    }
  ]
}

export const policyPreferences = [
  {
    id: 1,
    type: 'random',
    label: 'Random',
    level: 'random',
    variant: 'light',
    icon: 'fa fa-random',
    img: '/assets/img/svg/rules/start-up-outline.svg'
  },
  {
    id: 2,
    type: 'car',
    label: 'Car Protect',
    level: 'beginner',
    variant: 'success',
    icon: 'fa fa-car',
    img: '/assets/img/svg/policies/road-trip-outline.svg'
  },
  {
    id: 3,
    type: 'home',
    label: 'Home Plus',
    level: 'medium',
    variant: 'warning',
    icon: 'fa fa-home',
    img: '/assets/img/svg/policies/finance-app-outline.svg'
  },
  {
    id: 4,
    type: 'rent',
    label: 'Rent Smart',
    level: 'difficult',
    variant: 'danger',
    icon: 'fa fa-bed',
    img: '/assets/img/svg/policies/real-estate-agent-outline.svg'
  }
]

export const quickAllocations = {
  max: 30,
  step: 5
}

export const maxAllocations = 200

export const testData = [
  {
    user_id: 3,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test1',
    user_role: 'Team Leader',
    allocated: 100,
    lodged: 0,
    remaining: 100,
    policy_preference: 'home'
  },
  {
    user_id: 12,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test10',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 13,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test11',
    user_role: 'Team Member',
    allocated: 10,
    lodged: 0,
    remaining: 10,
    policy_preference: 'random'
  },
  {
    user_id: 14,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test12',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 15,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test13',
    user_role: 'Team Member',
    allocated: 20,
    lodged: 0,
    remaining: 20,
    policy_preference: 'random'
  },
  {
    user_id: 16,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test14',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 17,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test15',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 18,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test16',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 19,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test17',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 20,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test18',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 21,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test19',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 4,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test2',
    user_role: 'Team Member',
    allocated: 20,
    lodged: 0,
    remaining: 20,
    policy_preference: 'random'
  },
  {
    user_id: 22,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test20',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 5,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test3',
    user_role: 'Team Member',
    allocated: 15,
    lodged: 0,
    remaining: 15,
    policy_preference: 'random'
  },
  {
    user_id: 6,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test4',
    user_role: 'Team Member',
    allocated: 15,
    lodged: 0,
    remaining: 15,
    policy_preference: 'random'
  },
  {
    user_id: 7,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test5',
    user_role: 'Team Member',
    allocated: 15,
    lodged: 0,
    remaining: 15,
    policy_preference: 'random'
  },
  {
    user_id: 8,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test6',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 9,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test7',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 10,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test8',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  },
  {
    user_id: 11,
    avatar: 'https://perform.local/control-centre/storage/avatar.png',
    user_name: 'Jason Test9',
    user_role: 'Team Member',
    allocated: 0,
    lodged: 0,
    remaining: 0,
    policy_preference: 'random'
  }
]

export const weekDaySettingsToggle = [
  {
    id: 1,
    title: 'Game Progression',
    icon: 'icon-list-view-outline',
    order: 1,
    class: 'switch-button-case left '
  },
  {
    id: 2,
    title: 'Rollback',
    icon: 'icon-grid-view-outline',
    order: 2,
    class: 'switch-button-case right'
  }
]
