<template>
  <div class="work-allocation-base-input">
    <b-form-input
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      v-model="name"
      autocomplete="off"
      @input="validate ? v.$touch() : null"
      :step="step"
      v-tooltip="{
        content: error,
        show: validate && v.$error,
        trigger: 'manual',
        placement: 'bottom',
        classes: ['danger']
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    v: {
      type: Object,
      required: false
    },
    validate: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    step: {
      type: [String, Number],
      default: 1
    }
  },
  computed: {
    name: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
