<template>
  <div class="icon-toggle d-flex">
    <i
      class="align-self-center ml-2"
      v-for="(item, index) in toggleItems"
      :key="`item-${index}`"
      :class="[item.icon, item.id === selectedToggleId ? 'text-primary' : 'text-muted']"
      @click="onToggleSwitch(item.id)"
      v-tooltip="{
        content: item.title,
        placement: 'bottom-center',
        classes: ['light'],
        delay: {
          show: 500,
          hide: 300
        },
        offset: '5'
      }"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [
        {
          id: 1,
          title: 'first',
          icon: 'icon-list-view-outline',
          order: 1
        },
        {
          id: 2,
          title: 'second',
          icon: 'icon-grid-view-outline',
          order: 2
        }
      ]
    },
    value: {
      type: Number,
      default: 1
    }
  },
  methods: {
    onToggleSwitch(id) {
      let self = this
      self.selectedToggleId = id
    }
  },
  computed: {
    toggleItems() {
      let self = this
      return _.orderBy(self.options, 'order', 'asc')
    },
    selectedToggleId: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
