<template>
  <div class="work-allocations-control">
    <b-row>
      <b-col
        v-for="(work, index) in allWorkAllocations"
        :key="`work-${index}`"
        cols="3"
        class="my-3 text-center"
      >
        <work-allocation-input
          :key="`work-allocation-input-${index}`"
          :avatar="work.avatar"
          :userName="work.userName"
          v-model="work.workAllocations"
          :quickAssigned="allQuickAssigned"
        ></work-allocation-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import WorkAllocationInput from '@/components/Control/WorkAllocationInput.vue'
export default {
  props: {
    workAllocations: {
      type: Array,
      default: () => []
    },
    quickAssigned: {
      type: Number,
      default: 0
    }
  },
  components: {
    WorkAllocationInput
  },
  computed: {
    allWorkAllocations: {
      get() {
        return this.workAllocations
      }
    },
    allQuickAssigned: {
      get() {
        return this.quickAssigned
      }
    }
  },
  watch: {
    allWorkAllocations: {
      handler(work) {
        let allocated = work.filter(w => w.workAllocations > 0)
        let allocatedWork = allocated.map(w => {
          return { userId: w.userId, workAllocations: w.workAllocations }
        })
        this.$emit('workAllocationsUpdated', allocatedWork)
      },
      deep: true
    }
  }
}
</script>
