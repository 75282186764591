<template>
  <div class="base-toggle-button">
    <div class="switch-button" :style="`width:${buttonSize}px`">
      <span
        class="active btn-shadow"
        :style="selectedToggleId === 1 ? 'left:0%' : 'left:50%'"
      ></span>
      <button
        v-for="(item, index) in toggleItems"
        :key="`item-${index}`"
        :class="[item.class, item.id === selectedToggleId ? 'active-case' : '', 'font-bold']"
        @click="onToggleSwitch(item.id)"
        :disabled="disabled"
      >
        {{ item.title }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [
        {
          id: 1,
          title: 'first',
          icon: 'icon-list-view-outline',
          order: 1,
          class: 'switch-button-case left '
        },
        {
          id: 2,
          title: 'second',
          icon: 'icon-grid-view-outline',
          order: 2,
          class: 'switch-button-case right'
        }
      ]
    },
    value: {
      type: Number,
      default: 1
    },
    buttonSize: {
      type: Number,
      default: 300
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onToggleSwitch(id) {
      let self = this
      self.selectedToggleId = id
    }
  },
  computed: {
    toggleItems() {
      let self = this
      return _.orderBy(self.options, 'order', 'asc')
    },
    selectedToggleId: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
