<template>
  <div class="chart-legend weekly-result-chart-legend w-100">
    <b-table-simple hover>
      <b-tr class="week-result-header">
        <b-th class="w-20 align-middle text-center font-semi-bold"></b-th>
        <b-th
          v-for="(label, index) in labels"
          :key="`label-${index}`"
          class="w-15 align-middle text-center font-semi-bold"
        >
          {{ label }}
        </b-th>
      </b-tr>
      <b-tbody>
        <b-tr
          v-for="(row, index) in chartDataSet"
          :key="`chart-row-${index}`"
          :class="{ 'option-hidden': row.hidden }"
        >
          <b-td class="text-center align-middle legend-item w-20" @click="onDataClick(index)">
            <p
              class="align-self-center m-0 font-semi-bold team-name mx-auto"
              v-tooltip="{
                content: row.label,
                placement: 'top',
                classes: ['light']
              }"
            >
              {{ row.label }}
            </p>
            <div
              class="px-3 py-1 mb-1 mx-auto align-self-center chart-legend-line"
              :style="`border: 1px solid ${row.borderColor}; backgroundColor: ${row.backgroundColor}`"
            ></div>
          </b-td>
          <b-td
            v-for="(col, index) in row.data"
            :key="`chart-col-${index}`"
            class="align-middle w-15"
          >
            <p v-if="formatType === 2" class="m-0 text-center font-light">
              {{ col | formatPayment }}
            </p>
            <p v-else-if="formatType === 3" class="m-0 text-center font-light">
              {{ col | formatRatePercentage }}
            </p>
            <p v-else class="m-0 text-center font-light">{{ col }}</p>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import { formatPayment, formatRatePercentage } from '@/constants/config'
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    formatType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      chartDataSet: []
    }
  },
  methods: {
    initialiseOptions() {
      let self = this
      self.chartDataSet = _.cloneDeep(self.options)
    },
    onDataClick(index) {
      let self = this
      let clickedData = self.chartDataSet[index]
      clickedData.hidden = !clickedData.hidden
      self.$set(self.chartDataSet, index, clickedData)
      self.$emit('optionClicked', index)
    }
  },
  filters: {
    formatPayment: function (value) {
      return formatPayment(value)
    },
    formatRatePercentage: function (value) {
      return formatRatePercentage(value, 2)
    }
  },
  mounted() {
    this.initialiseOptions()
  }
}
</script>
