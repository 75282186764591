<template>
  <div class="form-body game-financials-control-slide-two">
    <b-form @submit.prevent="addNewFinancial" inline class="mb-4" ref="addFinancialForm">
      <b-table
        responsive
        hover
        ref="financialTable"
        :items="financials"
        :fields="fields"
        details-td-class="tdClass"
        sort-by="id"
        tbody-tr-class="body-tr"
        class="game-financials-table"
      >
        <template #top-row>
          <b-td>
            <label class="sr-only" for="inline-form-input-description">Description</label>
            <b-form-input
              required
              id="inline-form-input-description"
              class="mb-2 mb-sm-0 sm-input w-100"
              placeholder="Description"
              v-model="newFinancial.description"
            ></b-form-input>
          </b-td>
          <b-td class="th-amount">
            <label class="sr-only" for="inline-form-input-amount">Amount</label>
            <b-form-input
              required
              type="number"
              id="inline-form-input-amount"
              class="mb-2 mb-sm-0 sm-input w-100"
              placeholder="Amount"
              v-model="newFinancial.amount"
            ></b-form-input>
          </b-td>
          <b-td>
            <label class="sr-only" for="inline-form-input-type">Type</label>
            <b-dropdown variant="empty" toggle-class="p-0" menu-class="a-shadow-sm">
              <template slot="button-content">
                <span>{{ newFinancial.type }}</span>
              </template>
              <b-dropdown-item
                v-for="(item, index) in financialTypes"
                :key="`type-${index}`"
                :active="newFinancial.type == item"
                @click="newFinancial.type = item"
              >
                <span>{{ item }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-td>
          <b-td class="select">
            <label class="sr-only" for="inline-form-input-week">Week</label>
            <b-form-select
              size="sm"
              name="week"
              v-model="newFinancial.week"
              :options="availableWeeks"
              v-on:change="selectWeek(newFinancial)"
            ></b-form-select>
          </b-td>
          <b-td class="select">
            <label class="sr-only" for="inline-form-input-Day">Day</label>
            <b-form-select
              size="sm"
              v-model="newFinancial.day"
              :options="availableDays(newFinancial.week)"
              class="day"
            ></b-form-select>
          </b-td>
          <b-td>
            <div class="text-center">
              <i class="simple-icon-plus text-primary" @click="addFinancialForm()"></i></div
          ></b-td>
        </template>
        <template #cell(description)="row">
          <b-form-input
            required
            size="sm"
            v-if="row.item.editing && row.field.editable"
            v-model="row.item.description"
            class="mb-2 mb-sm-0 sm-input"
            placeholder="Description"
          />
          <div class="col" v-else :class="row.item.status == 'delete' ? 'disable' : ''">
            <span v-if="row.item.status == 'add'" class="new_span">
              <i class="fa fa-check-circle text-success"></i>
            </span>
            {{ row.item.description }}
          </div>
        </template>
        <template #cell(amount)="row">
          <b-form-input
            required
            type="number"
            size="sm"
            v-if="row.item.editing && row.field.editable"
            v-model="row.item.amount"
            class="mb-2 mb-sm-0 sm-input"
            placeholder="Amount"
            ref="amount"
          />
          <span v-else :class="row.item.status == 'delete' ? 'disable' : ''">
            {{ row.item.amount | formatAmount }}
          </span>
        </template>
        <template #cell(type)="row">
          <b-dropdown
            variant="empty"
            toggle-class="p-0"
            menu-class="a-shadow-sm"
            v-if="row.item.editing && row.field.editable"
          >
            <template slot="button-content">
              <span>{{ row.item.type }}</span>
            </template>
            <b-dropdown-item
              v-for="(item, index) in financialTypes"
              :key="`type-${index}`"
              :active="row.item.type == item"
              @click="row.item.type = item"
            >
              <span>{{ item }}</span>
            </b-dropdown-item>
          </b-dropdown>

          <span v-else :class="row.item.status == 'delete' ? 'disable' : ''">
            <b-badge
              :variant="
                row.item.status == 'delete'
                  ? 'light'
                  : row.item.type.toLowerCase() === 'credit'
                  ? 'info'
                  : 'warning'
              "
              pill
            >
              {{ row.item.type }}
            </b-badge>
          </span>
        </template>
        <template #cell(day)="row">
          <b-form-select
            v-if="row.item.editing && row.field.editable"
            name="day"
            v-model="row.item.day"
            :options="availableDays(row.item.week)"
            class="day"
          ></b-form-select>
          <span
            v-else
            class=""
            :class="row.item.status == 'delete' ? ['disable', 'disable-day'] : ''"
          >
            <b-badge :variant="row.item.status == 'delete' ? 'light' : 'outline-new'">
              {{ getDay(row.item.day) }}
            </b-badge>
          </span>
        </template>
        <template #cell(week)="row">
          <b-form-select
            v-if="row.item.editing && row.field.editable"
            name="week"
            v-model="row.item.week"
            :options="availableWeeks"
            v-on:change="selectWeek(row.item)"
          ></b-form-select>
          <span
            v-else
            class=""
            :class="row.item.status == 'delete' ? ['disable', 'disable-week'] : ''"
          >
            <b-badge :variant="row.item.status == 'delete' ? 'light' : 'outline-info'">
              Week {{ row.item.week }}
            </b-badge>
          </span>
        </template>

        <template #cell(actions)="row">
          <div>
            <i
              class="simple-icon-note text-primary align-self-center"
              v-if="!row.item.editing && row.item.status !== 'delete'"
              @click="doEdit(row.item)"
              v-tooltip="{
                content: `Edit`,
                placement: 'bottom-center',
                classes: ['light'],
                delay: {
                  show: 500,
                  hide: 300
                },
                offset: '5'
              }"
            ></i>
            <i
              class="simple-icon-check text-primary"
              v-else-if="row.item.editing && row.item.status !== 'delete'"
              @click="doSave(row.item)"
              v-tooltip="{
                content: `Save`,
                placement: 'bottom-center',
                classes: ['light'],
                delay: {
                  show: 500,
                  hide: 300
                },
                offset: '5'
              }"
            ></i>

            <i
              class="simple-icon-reload text-danger"
              @click="doRecover(row.item)"
              v-if="row.item.status == 'delete'"
              v-tooltip="{
                content: `Recover`,
                placement: 'bottom-center',
                classes: ['danger'],
                delay: {
                  show: 500,
                  hide: 300
                },
                offset: '5'
              }"
            ></i>
            <i
              class="simple-icon-trash ml-2 text-danger"
              @click="doDelete(row.item)"
              v-else
              v-tooltip="{
                content: `Delete`,
                placement: 'bottom-center',
                classes: ['danger'],
                delay: {
                  show: 500,
                  hide: 300
                },
                offset: '5'
              }"
            ></i>
          </div>
        </template>
      </b-table>
      <button ref="submit" style="display: none">Submit</button>
    </b-form>
  </div>
</template>

<script>
import BaseInput from '@/components/Common/BaseInput.vue'
import BaseSelection from '@/components/Common/BaseSelection.vue'
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
import { formatPayment } from '@/constants/config'
export default {
  props: {
    activeTeamWeekDays: {
      type: Object,
      default() {
        return {}
      }
    },
    completedTeamWeekDays: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    BaseInput,
    ProcessingButton,
    BaseSelection
  },
  data() {
    return {
      processing: false,
      financials: [],
      originalFinancials: [],
      newFinancial: this.getDefaultFinancial(),
      fields: [
        {
          key: 'description',
          editable: true,
          thClass: 'text-center',
          tdClass: 'align-middle text-center w-30',
          sortable: true
        },
        {
          key: 'amount',
          editable: true,
          thClass: 'text-center',
          tdClass: 'align-middle text-center w-25',
          sortable: true
        },
        {
          key: 'type',
          editable: true,
          thClass: 'text-center',
          tdClass: 'align-middle text-center w-15',
          sortable: true
        },
        {
          key: 'week',
          editable: true,
          thClass: 'text-center',
          tdClass: 'align-middle text-center w-10',
          sortable: true
        },
        {
          key: 'day',
          editable: true,
          thClass: 'text-center',
          tdClass: 'align-middle text-center w-10',
          sortable: true
        },
        {
          key: 'actions',
          thClass: 'text-center',
          tdClass: 'align-middle td-actions w-5 text-center'
        }
      ],
      weekDays: {
        1: 'MON',
        2: 'TUE',
        3: 'WED',
        4: 'THU',
        5: 'FRI'
      },
      availableDaysAndWeeks: [],
      actionTooltip: '',
      financialTypes: ['Debit', 'Credit']
    }
  },
  computed: {
    editableFields() {
      return this.fields.filter((field) => {
        return field.editable === true
      })
    },
    availableWeeks() {
      let weeks = []
      for (let i = 1; i <= this.activeTeamWeekDays.singleWeekId; i++) {
        weeks.push({
          value: i,
          text: 'Week' + i
        })
      }
      return weeks
    }
  },
  methods: {
    availableDays(selectedWeek) {
      let defaultDays = [
        { value: 1, text: 'MON', disabled: false },
        { value: 2, text: 'TUE', disabled: false },
        { value: 3, text: 'WED', disabled: false },
        { value: 4, text: 'THU', disabled: false },
        { value: 5, text: 'FRI', disabled: false }
      ]
      if (selectedWeek === this.activeTeamWeekDays.singleWeekId) {
        this.activeTeamWeekDays.weekDays.forEach((weekday, index) => {
          defaultDays[index].disabled = ![3, 4].includes(weekday.dayStatus)
        })
      }
      return defaultDays
    },
    setDefaultWeekAndDay() {
      this.newFinancial.week = this.activeTeamWeekDays.singleWeekId
      this.newFinancial.day = this.activeTeamWeekDays.weekDays.find(
        (day) => day.dayStatus == 3
      ).dayId
    },
    setFinancials() {
      let self = this
      let items = []
      let weekdays = _.cloneDeep(this.activeTeamWeekDays.weekDays)
      this.completedTeamWeekDays.forEach((ele) => {
        weekdays = weekdays.concat(ele.weekDays ?? [])
      })
      weekdays.map((d) => {
        d.financials.map((f) => {
          items.push({
            id: f.id,
            description: f.description,
            amount: Number(f.amount),
            type: f.type,
            day: d.dayId,
            week: d.weekId,
            editing: false,
            status: null
          })
        })
      })

      this.originalFinancials = _.cloneDeep(items)
      this.financials = items
    },
    doEdit(item) {
      item.editing = true
    },
    doSave(item) {
      item.status = null
      if (!item.id) {
        item.status = 'add'
      } else {
        let originalItem = this.originalFinancials.find((ele) => ele.id == item.id)
        if (
          !_.isEqual(
            _.omit(originalItem, ['status', 'editing']),
            _.omit(item, ['status', 'editing'])
          )
        ) {
          item.status = 'update'
        }
      }
      item.editing = false
    },
    doDelete(item) {
      item.status = 'delete'
      console.log(item)
      // this.financials.splice(index, 1)
    },
    doRecover(item) {
      if (!item.id) {
        //add
        item.status = 'add'
      } else {
        this.doSave(item)
      }
    },
    addFinancialForm() {
      this.$refs.submit.click()
    },
    async addNewFinancial() {
      await this.financials.unshift(this.newFinancial)
      this.$nextTick()
      this.newFinancial = await this.getDefaultFinancial()
      await this.setDefaultWeekAndDay()
    },
    applyWatcher() {
      this.$watch(
        'financials',
        function (value) {
          // console.log(value)
          let filterValue = value.filter(
            (obj) => !(obj.id == null && obj.status == 'delete') || obj.editing
          )
          this.$emit('settingsUpdated', { slideTwo: filterValue })
        },
        { deep: true }
      )
    },
    getDay(day) {
      return this.weekDays[day]
    },
    getDefaultFinancial() {
      return {
        id: null,
        description: '',
        amount: null,
        type: 'CREDIT',
        day: 1,
        week: 1,
        editing: false,
        status: 'add'
      }
    },
    selectWeek(item) {
      item.day = 1
    }
  },
  filters: {
    formatAmount: function (value) {
      return formatPayment(value)
    }
  },
  mounted() {
    this.setDefaultWeekAndDay()
    this.setFinancials()
    this.applyWatcher()
  }
}
</script>
