<template>
  <div class="quick-work-allocation-selector">
    <b-dropdown
      class="quick-allocations align-self-center"
      right
      variant="empty"
      toggle-class="p-0"
      no-caret
      menu-class="quick-allocations-menu mt-2 btn-shadow"
      :disabled="disabled"
    >
      <template #button-content>
        <span class="numberCircle">+</span>
        <div class="policy-icon animate__animated animate__bounceIn">
          <img src="/assets/img/svg/controls/full-inbox-outline.svg" alt="" />
        </div>
      </template>
      <b-dropdown-item
        v-for="(option, index) in quickAssignedOptions"
        :key="`allocation-${index}`"
        @click="onQuickAllocationClick(option)"
        class="d-flex"
      >
        <i class="fa fa-book text-primary align-self-center"></i> x
        <span class="align-self-center">{{ option }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    max: {
      type: Number,
      default: 30
    },
    step: {
      type: Number,
      default: 5
    },
    value: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    onQuickAllocationClick(option) {
      this.allocation = option
    }
  },
  computed: {
    quickAssignedOptions() {
      let self = this
      return _.range(5, self.max + self.step, self.step)
    },
    allocation: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-item {
  padding: 0.75rem 1.5rem;
}
.quick-allocations {
  .numberCircle {
    position: absolute;
    top: -6px;
    right: -6px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: #ff4949;
    border: 2px solid #ff4949;
    color: #fff;
    text-align: center;
    font: 12px Arial, sans-serif;
    z-index: 9;
  }

  i {
    font-size: 1.2rem;
    cursor: pointer;
  }

  img {
    height: 25px;
    vertical-align: middle;
    cursor: pointer;
  }

  .active {
    color: #e7523e;
  }

  &-scroll {
    height: 250px;
    margin-right: unset;
    padding-right: unset;

    .ps__thumb-y {
      right: 0;
    }
  }
}
</style>
