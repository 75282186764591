<template>
  <b-overlay :show="loading" rounded="sm" :opacity="opacity">
    <slot name="content" />
    <template #overlay>
      <pulse-loader
        :loading="loading"
        :color="fill"
        :size="loaderSize"
        class="align-self-center"
      ></pulse-loader>
    </template>
  </b-overlay>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loaderSize: {
      type: String,
      default: '5px'
    },
    opacity: {
      type: String,
      default: '0.7'
    }
  },
  components: {
    PulseLoader
  },
  data() {
    return {
      fill: variables.primaryColor
    }
  }
}
</script>
