<template>
  <div class="work-allocation-input">
    <b-avatar
      :src="avatar"
      size="4.5em"
      :text="getNameInitials(userName)"
      class="align-self-center"
      variant="light"
      :badge="workAllocations > 0"
      badge-top
      :badge-variant="hasNew ? 'warning' : 'new'"
      badge-offset="-5px"
      button
    >
      <template #badge v-if="workAllocations > 0">
        <span>{{ workAllocations }}</span>
      </template>
    </b-avatar>
    <p class="mt-2 mb-1">{{ userName }}</p>
    <div class="d-flex w-50 m-auto work-volume text-center">
      <div
        class="align-self-center p-1 control-area"
        :class="{ disabled: !hasNew }"
        v-tooltip="{
          content: 'Decrease',
          placement: 'bottom',
          classes: ['light'],
          offset: 5
        }"
        v-on="hasNew ? { click: decrement } : {}"
      >
        <div class="arrow-left align-self-center"></div>
      </div>
      <input
        type="number"
        class="work-volume-count mx-auto align-self-center"
        name="work-volume"
        v-model="newWorkAllocations"
        min="0"
        oninput="validity.valid||(value='');"
      />
      <div
        class="align-self-center p-1 control-area"
        v-tooltip="{
          content: 'Increase',
          placement: 'bottom',
          classes: ['light'],
          offset: 5
        }"
        @click="increment"
      >
        <div class="arrow-right align-self-center ml-auto"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { helperMixin } from '@/mixins/helperMixin'
export default {
  props: {
    avatar: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: 0
    },
    quickAssigned: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      initValue: 0,
      newWorkAllocations: 0
    }
  },
  mixins: [helperMixin],
  methods: {
    increment() {
      let self = this
      self.newWorkAllocations++
    },
    decrement() {
      let self = this
      if (self.newWorkAllocations > 0) {
        self.newWorkAllocations--
      } else {
        self.newWorkAllocations = 0
      }
    },
    setInitValue() {
      let self = this
      self.initValue = _.clone(self.value)
    }
  },
  computed: {
    workAllocations: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    hasNew() {
      let self = this
      return self.newWorkAllocations > 0 ? true : false
    },
    allQuickAssigned: {
      get() {
        return this.quickAssigned
      }
    }
  },
  created() {
    this.setInitValue()
  },
  watch: {
    newWorkAllocations(newValue) {
      let self = this
      self.workAllocations = self.initValue + Number(newValue)
    },
    allQuickAssigned(newValue) {
      let self = this
      self.newWorkAllocations = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.control-area {
  &:hover {
    cursor: pointer;

    .arrow-left {
      border-right: 0.35rem solid #545454;
    }

    .arrow-right {
      border-left: 0.35rem solid #545454;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .arrow-left {
      border-right: 0.35rem solid #cecece;
    }
  }
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 0.35rem solid transparent;
  border-bottom: 0.35rem solid transparent;
  border-left: 0.35rem solid #cecece;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 0.35rem solid transparent;
  border-bottom: 0.35rem solid transparent;
  border-right: 0.35rem solid #cecece;

  // &:hover {
  //   cursor: pointer;
  //   border-right: 0.35rem solid #545454;
  // }

  // &.disabled {
  //   cursor: not-allowed;
  //   border-right: 0.35rem solid #cecece;
  // }
}
.work-volume {
  &-count {
    color: #545454;
    display: inline-block;
    line-height: 1rem;
    padding: 0 2px;
    min-width: 1rem;
    text-align: center;
  }

  input {
    border: 0;
    // width: 15%;
    font-size: 1rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
